.work-time {
  &:before {
    content: 'alpha';
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    font-size: 30vw;
    z-index: -1;
    opacity: 0.1;
    transform: translateY(20vw) rotate(45deg);
  }

  summary {
    list-style: none;

    &::-webkit-details-marker {
      display: none;
    }
  }

  @keyframes fade-in {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  max-width: 350px;

  &__header {
    background: var(--chakra-colors-brand-light-txt);
    color: hsl(33, 100%, 98%);
    padding: 1.25rem 0.75rem 1.25rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    box-shadow:
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    position: relative;
    z-index: 20;
  }

  &__title {
    font-weight: 800;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    line-height: 1;
  }

  &__content {
    margin-top: -0.5rem;
    z-index: 19;
    position: relative;

    summary {
      display: flex;
      justify-content: center;
      transition: all 200ms ease-in-out;

      svg {
        transition: all 200ms ease-in-out;
      }
    }
  }

  &__timer {
    font-weight: 400;
    font-size: 1.2rem;
  }

  &__overline {
    font-size: 0.8em;
    font-weight: 400;
    display: block;
    color: rgba(255, 255, 255, 0.63);
    margin-bottom: 0.3rem;
    line-height: 1;
  }

  &__label {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    line-height: 1;
  }

  &__list {
    display: grid;
    gap: 0.75rem;
    border-radius: 8px;
    background-color: #e2e8f0;
    padding: 1.25rem;
    margin: 0;
    box-shadow:
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  &__entry {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &__column {
    font-weight: 800;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    line-height: 1;

    &--no-entry {
      grid-column: span 3;
    }
  }

  &__desc {
    font-size: 0.7em;
    font-weight: 400;
    display: block;
    color: rgb(0, 0, 0);
    margin-bottom: 0.3rem;
    line-height: 1;
  }

  &__manual {
    display: grid;
    z-index: 19;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr) auto;
    gap: 0.75rem;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #e2e8f0;
    padding: 1.75rem 1.25rem 1.25rem;
    margin-top: -0.75rem;
  }
}

.work-time:hover .work-time__content,
.work-time__content[open] {
  svg {
    transform: scale(1.2) translateY(1rem);
    fill: black;
  }
}

.work-time__content[open] {
  svg {
    transform: scale(1.2) translateY(1rem) rotate(180deg);
  }
}

.work-time:hover .work-time__content[open] {
  svg {
    transform: scale(1.2) rotate(180deg);
  }
}

.work-time-history {
  background-color: #e2e8f0;

  display: grid;
  gap: 0.75rem;
  border-radius: 15px;

  &__entry {
    summary {
      background: var(--chakra-colors-brand-light-txt);
      color: hsl(33, 100%, 98%);
      padding: 1.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 15px;
      box-shadow:
        0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);

      svg {
        transition: all 200ms ease-in-out;
      }
    }

    &[open] {
      summary svg {
        transform: rotate(180deg);
      }
    }
  }

  &__headline {
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    line-height: 1;
  }

  &__list {
    background-color: #e2e8f0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    list-style: none;
    margin: 0;
    padding: 1.25rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__day {
    display: flex;
    padding-bottom: 1.25rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__date {
  }
}

.dropdown {
  &-wrapper {
    position: relative;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__menu {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 10px 20px rgba(#404040, 0.15);
    top: 100%;
    position: absolute;
    right: 0;
    z-index: 1000;
    pointer-events: all;
  }

  &__list {
    margin: 0;
    width: max-content;
    padding: 8px;
    display: grid;

    & + & {
      border-top: 1px solid #ddd;
    }
  }

  &__button {
    font-size: 0.8em;
    border: 0;
    padding: 8px;
    width: 100%;
    border-radius: 4px;
    text-align: left;
    display: flex;
    align-items: center;
    position: relative;
    color: #000;

    &:hover {
      background-color: #f1f3f7;

      & + .menu-sub-list {
        display: flex;
      }

      svg {
        stroke: #3a3c42;
      }
    }

    svg {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      stroke: var(--color-text-primary-offset);

      &:nth-of-type(2) {
        margin-right: 0;
        position: absolute;
        right: 8px;
      }
    }
  }
}

.statistics {
  // min-width: 360px;

  .link {
    color: #05d678;

    &:hover {
      text-decoration: underline;
    }
  }

  .bar {
    display: block;
    background-color: #f6f6f6;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;

    &__process {
      position: absolute;
      height: 100%;
      width: 1%;
      top: 0;
      left: 0;
      background: #05d678;
    }
  }

  &__group {
    display: grid;
    font-size: 14px;

    &-item {
      padding-block: 0.4em;
      grid-column: span 1;

      &.span-2 {
        grid-column: span 2;
      }

      &.number {
        font-variant-numeric: tabular-nums;
      }

      &.center {
        align-self: center;
      }
    }

    &-row {
      padding-inline: 0.5rem;
      grid-column: span 4;
      border-top: 1px solid #e6e6e6;
      display: grid;
      gap: 0 0.5rem;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      &--sub {
        font-size: 13px;
        color: rgb(0 0 0 / 0.85);
      }

      &-3 {
        grid-template-columns: 1fr 1fr 1fr;
      }

      &-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      &-5 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      &-6 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      &-7 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }
    }

    &:nth-child(odd) {
      .statistics__group-row-alt:nth-child(odd) {
        background: #fafafa;
      }

      .statistics__group-row--sub:nth-child(odd) {
        background: #fcfcfc;
      }
    }
  }
}

.statistics__group-row--sub .statistics__group-item.number {
  font-weight: 400;
}

.statistics__group-row--sub .bar__process {
  background: #d3ded9;
}

.time-tracking-calendar {
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #e6e6e6;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: #05d678; // #2C3E50;
  --fc-button-border-color: #05d678; // #2C3E50;
  --fc-button-hover-bg-color: #00bb69; // #1e2b37;
  --fc-button-hover-border-color: #00bb69; // #1a252f;
  --fc-button-active-bg-color: #00bb69; // #1a252f;
  --fc-button-active-border-color: #00bb69; // #151e27;

  --fc-event-bg-color: #eefff8; // #eefff8; // #3788d8;
  --fc-event-border-color: #05d678; // #05d678; // #3788d8;
  --fc-event-text-color: #05d678; // #05d678;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.1);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: #fcfcfc; // rgba(255, 220, 40, 0.15);
  --fc-now-indicator-color: #ff6b00;

  height: 100%;

  .fc-v-event {
    border: none;
    border-left: 2px solid var(--fc-event-border-color);
    background-color: var(--fc-event-bg-color);

    &:hover,
    &:focus {
      color: #fff;
      background-color: var(--fc-event-border-color);

      .fc-event-main {
        color: #fff;
      }

      .mm-time-cell__project {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    &.mm-time-event--off-day,
    &.mm-time-event--sick-day {
      border-left-color: gray;
      background-color: rgba(249, 250, 251);
      background: repeating-linear-gradient(
        45deg,
        rgba(249, 250, 251),
        rgba(249, 250, 251) 4px,
        #fff 4px,
        #fff 8px
      );
      pointer-events: none;
      background-clip: padding-box;

      .fc-event-main {
        color: gray !important;
      }

      .mm-time-cell__time {
        display: none !important;
      }

      .mm-time-cell__title {
        font-weight: bold;
      }
    }
  }

  .fc-v-event .fc-event-main {
    &:hover,
    &:focus {
      color: #fff;
    }
  }

  .fc-event-selected,
  .fc-event:focus {
    color: #fff;
    background-color: var(--fc-event-border-color);

    .fc-event-main {
      color: #fff;
      background-color: var(--fc-event-border-color);
    }

    &:before {
      background: transparent;
    }

    &:after {
      background: transparent;
    }
  }

  .fc-timegrid-event {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .fc .fc-timegrid-slot-minor {
    border-color: #f6f6f6;
    border-style: solid;
  }

  .is-project {
    --fc-event-border-color: #05d678; // #05d678;
    --fc-event-text-color: #05d678; // #05d678;
    --fc-event-bg-color: #eefff8; // #05d678;
  }

  .mm-time-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    padding: 0.25em;
    overflow: hidden;
    user-select: none;

    &__time {
      flex-shrink: 0;
      flex-grow: 0;
      white-space: nowrap;
      margin-right: 0.25em;
    }

    &__title {
    }

    &__project {
      margin-top: auto;
      margin-bottom: 4px;
      max-width: 90%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 1;
      background-color: rgba(#ff6b00, 0.1); //rgba(0, 214, 120, 0.1);
      border-radius: 4px;
      padding: 0.25em 0.5em;
      color: #ff6b00;
    }

    &--medium {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.1em 0.25em;
      font-size: 12px;

      .mm-time-cell__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1 1 25%;
      }

      .mm-time-cell__project {
        margin-top: initial;
      }
    }

    &--short {
      flex-direction: row;
      padding: 0 0.25em;
      font-size: 12px;

      .mm-time-cell__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }

      .mm-time-cell__project {
        margin-top: initial;
        flex: 1 1 40%;
        padding: 0.2em 0.5em;
      }
    }
  }

  .fc-theme-standard .fc-scrollgrid {
    border-width: 0;
  }

  .fc .fc-timegrid-now-indicator-line {
    border-width: 1.5px;
  }

  dialog {
    border: none !important;
    border-radius: 8px;
    box-shadow:
      0 0 #0000,
      0 0 #0000,
      0 25px 50px -12px rgba(0, 0, 0, 0.25);
    padding: 2rem;
    max-width: 640px;
    background: rgba(255, 255, 255, 0.95);
    z-index: 99999;
  }
}
