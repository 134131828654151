@import 'variables';
@import 'mixins';
@import 'typography';
@import 'editor';
@import 'base';
@import 'components/App';
@import 'components/sessionExpiredModal';
@import 'components/dropdownmenu';
@import 'components/floatingSelect';
@import 'components/btn';
@import 'components/schedule';
@import 'components/sidebar';
@import 'components/AddProjects';
@import 'components/Telefondienst';
@import 'components/customerList';
@import 'components/EditProjectCard';
@import 'components/SpecialPage';
@import 'components/LoginForm';
@import 'components/dashboard';
@import 'components/markdown';
@import 'components/forms';
@import 'components/widget';
@import 'components/time-tracking-page';
@import 'components/work-time';
@import 'components/tasks';
@import 'components/timeline';
@import 'components/time-tracking';
@import 'components/margrit';
@import 'components/dialog';
@import 'components/combobox';
@import 'components/content-editor';

.pagetitle {
  @include pagetitle;
}

.title-1 {
  @include title-1;
}

.title-1-s {
  @include title-1-s;
}

.title-2 {
  @include title-2;
}

.title-3 {
  @include title-3;
}

.text {
  @include text;
}

.title-2.underline,
.title-3.underline {
  text-decoration: underline;
}

.title-3.underline {
  margin-bottom: 0.5em;
}

.strikethrough {
  text-decoration: line-through;
}

.push--right {
  margin-left: auto;
}

.truncate-text {
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-1 small,
.title-1-s small,
.title-2 small,
.title-3 small {
  display: block;
  font-size: inherit;
}

.no-wrap {
  white-space: nowrap;
}

.recent-projects {
}

.link--default {
  color: var(--color-mm-green);
}

.action--destructive {
  color: var(--color-mm-red) !important;
  opacity: 1 !important;
}

section.section {
  padding: 3rem 0;
}

.grid-stack {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.scroll-stack {
  display: flex;
  gap: 4em;
  align-items: stretch;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &--vertical {
    flex-direction: column;
    gap: 1em;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }
}

.content-secondary {
  color: #ccc;
}

.layout {
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  // grid-template-columns: repeat(auto-fit, minmax(960px, 1fr));

  &--columns {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3rem;
    @include respond-to('m') {
      flex-direction: row;
    }

    &__left {
      width: 100%;
    }

    &__right {
      width: 100%;
      padding: 0 2rem;
      flex-shrink: 1;

      @include respond-to('m') {
        max-width: 50%;
      }
    }
  }
}

.header {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.post {
  position: relative;
  display: block;
  border-radius: 8px;
  padding: 1rem 1rem;
  transition: background-color 0.15s;

  .icon {
    width: 20px;
    display: inline;
    margin-right: 1rem;
    vertical-align: top;
    fill: currentColor;
  }

  &__subline {
    margin-left: 36px;
    opacity: 0.5;
  }

  &__title {
    display: inline;
    @include headline;
  }

  &__tags {
    display: inline;
    @include headline;
    margin-left: 0.5rem;
    opacity: 0.5;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.025);
  }

  &--highlight {
    background-color: rgba(0, 0, 0, 0.025);

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
}

.link {
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: auto;
  grid-gap: 1rem;
  grid-template-areas: 'image content';
  padding: 2rem 8px;
  border-radius: 8px;
  transition: background-color 0.15s;
  margin-bottom: 1.25rem;

  &__image {
    grid-area: image;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    background: #fafafa;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__icon {
    margin: 0 auto;
    width: 32px;
    display: block;
    fill: currentColor;
    opacity: 0.2;
  }

  &__content {
    grid-area: content;
    display: grid;
    padding-block: 0;
    gap: 0.5rem;
    max-width: 64ch;
  }

  &__destination {
    grid-column: 1 / -1;
    grid-row: 1;
  }

  &__subline {
    opacity: 0.4; // 0.5;
    font-size: 0.9em; //.8rem;
    display: inline; //block;
    margin-left: 0.5em;
  }

  &__description {
    opacity: 0.8;
  }

  &__title {
    display: inline;
    @include headline;
  }

  &__tags {
    display: flex;
    font-size: 14px;
    line-height: 1.2;
    gap: 4px;
    margin-top: 1rem;

    span {
      display: block;
      font-size: 14px;
      padding: 0.3em 0.9em;
      line-height: 1;
      border-radius: 16px;
      background: rgba(0, 0, 0, 0.025);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.025);
  }
}

.button-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 4rem;

  button {
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.025);
    padding: 0.5rem 1rem;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.error-message {
  padding: 2rem;
  background: white;
  margin: 2rem 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 5px solid red;

  p {
    margin: 0;
    font-weight: 300;
  }

  strong {
    margin-right: 1rem;
  }
}

.large {
  border: 1px solid rgba(0, 0, 0, 0.35);
  font-size: 3vw;
  padding: 1vw;
}

.button__today {
  &--disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}

/*
	Use in conjunction with .sr-only to only display content when it's focused.
	@note Useful for skip links
	@see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
	@note Based on a HTML5 Boilerplate technique, included in Bootstrap
	@note Fixed a bug with position: static on iOS 10.0.2 + VoiceOver
		@author Sylvain Pigeard
		@see https://github.com/twbs/bootstrap/issues/20732
*/
.sr-only-focusable:focus,
.sr-only-focusable:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}

#nav--main {
  height: 100%;
}

.nav--main {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0.75rem 2rem;
  gap: 2rem;
  font-weight: 400;
  justify-content: flex-start;
  align-items: center;
  background-color: #f5f5f5;

  a {
    white-space: nowrap;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  .active {
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  &__brand {
    @include headline;
  }

  &__section {
    margin-top: 1.5em;
    padding: 0.75em 0.25em;
  }

  &--sidebar {
    background-color: transparent;
    display: block;
    padding: 0;

    a {
      display: flex;
      align-items: center;
      white-space: nowrap;
      opacity: 0.5;
      padding: 0.25em 0.25em;
      gap: 8px;

      span {
        width: 16px;
        height: auto;
      }
    }

    .active {
      opacity: 0.75;
    }

    &:last-of-type {
      padding-bottom: 4rem;
    }
  }
}

.label {
  display: inline-block;
  background: #f5f5f5;
  border-radius: 20px;
  padding: 0.25em 1.5em;
  margin: 1em 0;
}

.dropdown--native {
  --dropdown-border-radius: 0;

  display: inline-block;
  position: relative;

  & > summary {
    cursor: pointer;
    list-style: none;
  }

  & > summary::marker, /* Latest Chrome, Edge, Firefox */
  & > summary::-webkit-details-marker /* Safari */ {
    display: none;
  }

  &[open] .btn__caret {
    transform: rotate(45deg);
  }

  &[open] > summary::before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 80;
    display: block;
    cursor: default;
    content: ' ';
    background: rgba(0, 0, 0, 0.2);
  }

  ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    min-width: 100%;
    max-width: 280px;
    // margin-top: -1px;
    margin-top: 0.5em;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    color: var(--color-txt);
    border: 1px solid var(--color-txt);
    border-radius: var(--dropdown-border-radius);
    // box-shadow: 0 2px 4px #ccc;

    .push--right & {
      left: auto;
      right: 0;
    }

    & > * + * {
      border-top: 1px solid var(--color-txt);
    }

    a,
    button[type='button'] {
      display: block;
      padding: 0.65em 2em 0.65em 1em !important;
      color: inherit;
      text-decoration: none !important;
      white-space: nowrap;

      &:hover,
      &:focus,
      &:active {
        color: var(--color-mm-green);
      }
    }
  }
}

.sidebar-layout {
  width: 100%;
  height: 100%;
  display: flex;

  &__aside {
    width: 100%;
    height: 100%;
    max-width: 240px;
    padding: 2rem 1rem;
    background: #fafafa;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(-100%);
    transition: transform 0.25s;
    overflow-y: auto;

    @media screen and (min-width: 1200px) {
      position: relative !important;
      transform: translate(0) !important;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 2rem clamp(1rem, 1rem + 1vw, 3rem);
    overflow-scrolling: touch;

    &:before {
      @media screen and (min-width: 1200px) {
        content: none !important;
      }
    }
  }
}

#menuToggle {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  transition: transform 0.25s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  z-index: 1001;

  svg {
    width: 26px;
    height: auto;
  }

  &:hover {
    svg path {
      fill: var(--color-mm-green);
    }
  }

  @media screen and (min-width: 1200px) {
    display: none !important;
  }
}

.sidebar-layout--open {
  .sidebar-layout__aside {
    transform: translate(0);
    z-index: 999;
  }

  .sidebar-layout__content {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.9);
      z-index: 888;
    }
  }

  #menuToggle {
    // transform: translateX(240px);
    transform: rotate(36deg);
    z-index: 1000;
  }
}

.inline-input {
  width: 48px;
  background: #f5f5f5;
  display: inline-block;
  min-width: auto;
  border-radius: 8px;
  padding: 4px;
  text-align: center;

  &--medium {
    width: 64px;
  }

  &--large {
    width: 96px;
  }
}

.calculator {
  &__fact {
    margin-top: 1em;
  }

  &__important {
    color: var(--color-mm-green);
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

.horizontal-stack {
  display: flex;
  align-items: baseline;

  &.with-gap {
    gap: 1rem;
  }
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 4rem;

  button {
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.025);
    padding: 0.25rem 1rem;
    font-size: 0.9rem;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .button--selected {
    background-color: var(--color-mm-green);
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--color-mm-green-active);
    }
  }
}

@media print {
  @page {
    margin: 1cm 1cm 1cm 1cm;
    size: A4;
  }
}

.app_layout_chat {
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
}
